import { Links, Scripts, LiveReload } from '@ubo/losse-sjedel'
import { AdminPanel, LosseLink } from '@ubo/losse-sjedel'

export default function PageNotFound() {
  return (
    <html lang="nl">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body>
        <div className="min-h-full bg-gp-joltik px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main className="sm:flex">
              <p className="text-4xl font-extrabold text-gp-orbeeltle sm:text-5xl">404</p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gp-electabuzz sm:pl-6">
                  <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl">Pagina niet gevonden</h1>
                  <p className="mt-1 text-base text-black/75">Controleer de URL in de adresbalk en probeer het opnieuw.</p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <LosseLink to="/" className="btn">
                    Ga naar home
                  </LosseLink>
                  <LosseLink to="/contact" className="btn">
                    Contact
                  </LosseLink>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Scripts />
        <LiveReload />
        <AdminPanel />
      </body>
    </html>
  )
}
